<template>
  <div class="dealDetail">
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <a @click="prev">交易市场</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>农机详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <div class="dealDetail_top">
        <el-row>
          <el-col :span="8">
            <div class="ddt_imgBox">
              <div class="ddt_ib_showImg">
                <img :src="showImg" alt />
              </div>
              <div class="ddt_ib_btn">
                <ul class="ddt_ib_btnBox">
                  <li class="ddt_ib_btnBlock" v-for="(item,index) in detailList.imgs" :key="index">
                    <img :src="item" alt @click="changeImg(item)" />
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="16">
            <el-row class="ddt_goodsName">{{detailList.title}}</el-row>
            <el-row class="ddt_goodsName ddt_goodsName2">
              <span class="ddt_spanBlock">价格：</span><span class="ddt_priceBol">￥</span>
              <span class="ddtb_bigfont ddtb_bigfont2">{{detailList.price}}</span>
            </el-row>
            <el-row class="ddt_defaultRow ddt_defaultRow2">机械种类：{{detailList.machineType}}</el-row>
            <el-row class="ddt_defaultRow ddt_defaultRow2">
              <span class="ddt_onHirTime">起租日期：{{detailList.onHireTime}}</span>
              <span>最短租期：{{detailList.tenancy}}天</span>
            </el-row>
            <el-row class="ddt_defaultRow ddt_defaultRow2"><span class="ddt_spanBlock">押金：</span>{{detailList.cashPledge}}元</el-row>
            <el-row class="ddt_defaultRow ddt_defaultRow2">
              <span class="ddt_onHirTime">送货方式：自取</span>
              <span>所在地区： {{detailList.city}}{{detailList.district}}</span>
            </el-row>
            <el-row class="ddt_defaultRow ddt_defaultRow2">
              <p class="ddt_onHirTime"><span class="ddt_spanBlock">联系人：</span>{{detailList.name}}</p>
              <span>联系电话：{{detailList.phone}}</span>
            </el-row>
            <el-row class="ddt_BtnBox">
              <div class="ddt_phoneBtn" @click="toApp">打电话</div>
              <div class="ddt_buyBtn" @click="toApp">立即{{btnShow}}</div>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div class="dealDetail_bottom clearfix">
        <div class="ddb_right ddb_right2">
          <el-row>
            <div class="ddb_right_top">商品详情</div>
          </el-row>
          <div class="ddb_right_main">
            <el-row class="ddbr_block_title">描述介绍</el-row>
            <div class="ddbr_block_borderBox ddbr_block_borderBox2">
              <el-row class="ddbrb_borderBox_row">
                <el-col>{{detailList.description || '暂无'}}</el-col>
              </el-row>
            </div>
            <el-row class="ddbr_block_title">优质推荐</el-row>
            <el-row class="ddbr_block_p">
                <div class="ddb_lb_block" v-for="(item,index) in alsoSell" :key="index" @click="toOther(item.id)">
                  <el-col :span="6">
                    <div class="ddb_lb_block_top">
                      <img :src="item.imgs[0] || 'http://photocdn.sohu.com/20120323/Img338614056.jpg'" alt />
                    </div>
                    <div class="ddb_lb_block_bottom ddb_lb_block_bottom2">
                      <el-row class="ddb_lb_bb_price ddb_lb_bb_labs">￥{{item.price}}元</el-row>
                      <el-row class="ddb_lb_bb_name ddb_lb_bb_name2 ddb_lb_bb_labs">{{item.title}}</el-row>
                    </div>
                  </el-col>
                </div>
            </el-row>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../src/pages/market/market.css";
export default {
  data() {
    return {
      id: this.$route.query.id,
      detailList: [],
      alsoSell: [],
      specification: [],
      updataTime: "",
      showImg: "",
      price: "",
      btnShow: "",
      transferType: this.$route.query.transferType
    };
  },
  mounted() {
    this.marketDetailList();
    this.marketRandomList();
    this.showWhichBtn();
  },
  methods: {
    prev() {
       this.$router.push({
        path: "/farmingMachinery",
        query: {
          id: '58058a421ea2ce524b92b1f81a439a9e'
        }
      });
    },
    showWhichBtn() {
      const nowpath = this.$route.query.flag;
      switch (nowpath) {
        case "buy":
          this.btnShow = "采购";
          break;
        case "rent":
          this.btnShow = "租用";
          break;
      }
    },
    toApp() {
      this.$router.push({
        path: "/downLoad"
      });
    },
    changeImg(num) {
      this.showImg = num;
    },
    timestampToTime(val) {
      const date = new Date(parseInt(val)), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ";
      return Y + M + D;
    },
    async marketRandomList() {
      const api = this.$fetchApi.queryMachineTransferList.api;
      
      const data = await this.$fetchData.fetchPost({
          pageNum: 1,
          pageSize: 4,
          recommend: true,
          transferType: this.transferType
        }, api, "json" );
      if (data.code === "200" && data.result) {
        this.alsoSell = data.result.rows;
      }
    },
    async marketDetailList() {
      
      // const api = this.$fetchApi.marketDetailList.api + this.id + "/2";
      const api = this.$fetchApi.queryMachineTransDetailList.api + this.id ;

      const data = await this.$fetchData.fetchGet({}, api, "json");
      if (data.code === "200" && data.result) {
        this.detailList = data.result;
        this.updataTime = this.timestampToTime(data.timestamp);
        this.showImg = this.detailList.imgs[0];
        // this.specification = this.detailList.specification;
        // if (this.detailList.price != null) {
        //   this.price = this.detailList.specification[0].value;
        // } else {
        //   this.price = "价格面议";
        // }
      }
    },
    toOther(id) {
      this.$router.push({
        path: "/market/farmingMachineryDetail",
        query: {
          id,
          flag: this.$route.query.flag
        }
      });
    },
    changePrice(val) {
      this.price = val;
    }
  }
};
</script>
<style scoped>
</style>